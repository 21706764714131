import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_INVITE_VENDOR',
  request: (id,data) => request.post(`/admins/vendors/${id}/admins`, data).then(r => r?.data?.data?.vendor),
  reducers: {
    success: (state, { response: vendor }) => ({
      ...state,
      vendors: {
        ...state.vendors,
        [vendor.id]: vendor,
      },
    }),
  },
};

export const {
  action: inviteVendor,
  reducer,
  usePending: useInviteVendorPending,
} = generate.redux(creator);

export const initialState = {
  vendors: {},
};
