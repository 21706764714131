import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMINS_CALENDAR_CANCEL_APPOINTMENT',
  request: (typeId, id) => request.post(`/admins/appointment-types/${typeId}/appointments/${id}/cancel`)
    .then(r => r?.data?.data?.appointment),
};

export const {
  action: cancelAppointment,
  reducer,
  usePending: useCancelAppointmentPending,
} = generate.redux(creator);

export const initialState = {};
