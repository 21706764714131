import { generate, request } from '@moved/services';

const creator = {
  namespace: 'ADMINS_CALENDAR_CREATE_APPOINTMENT',
  request: (typeId, data) => request.post(`/admins/appointment-types/${typeId}/appointments`, data)
    .then(r => r?.data?.data?.appointment),
};

export const {
  action: createAppointment,
  reducer,
  usePending: useCreateAppointmentPending,
} = generate.redux(creator);

export const initialState = {};
