import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CALENDAR_UPDATE_APPOINTMENT_REQUEST',
  request: (typeId, requestId, action='approve') => request.post(`/admins/appointment-types/${typeId}/appointment-requests/${requestId}/${action}`)
    .then(r => r.data.data.building_calendar_request),
};

export const {
  action: updateAppointmentRequest,
  reducer,
  usePending: useUpdateAppointmentRequestPending,
} = generate.redux(creator);

export const initialState = {};
