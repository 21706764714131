import { generate, request } from '@moved/services';

const creator = {
  namespace: 'CX_VENDORS_CREATE_VENDOR',
  request: (data) => request.post(`/admins/vendors`, data).then(r => r?.data?.data?.vendor),
  reducers: {
    success: (state, { response: vendor }) => ({
      ...state,
      vendors: {
        ...state.vendors,
        [vendor.id]: vendor,
      },
    }),
  },
};

export const {
  action: createVendor,
  reducer,
  usePending: useCreateVendorPending,
} = generate.redux(creator);

export const initialState = {
  vendors: {},
};
